<template>
  <div v-if="is_call_api">
    <builder-content
      :dataTest="test"
      :testId="idTest"
      :courseId="courseId"
      :type_form_test="type_form_test"
      :is_free="is_free"
      :offset="offset"
    ></builder-content>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import constantTestBuilder from "./mixins/Support/constantTestBuilder";
import transformSkillTestData from "./mixins/Support/transformSkillTestData";
export default {
  name: "EditTest",
  components: {
    // BuilderContent: () => import('./BuilderContent'),
    BuilderContent: () => import("./Form/TestBuilderForm"),
  },
  mixins: [
      constantTestBuilder,
      transformSkillTestData,
  ],
  props: ["courseId", "type_form_test", "is_free", "offset"],
  data() {
    return {
      is_call_api: false,
      test: {},
    };
  },
  computed: {
    idTest: function () {
      return this.$route.params.id;
    },
  },
  async created() {
    await this.getTestById(this.idTest);
    this.is_call_api = true;
  },
  methods: {
    async getTestById(id) {
      let vm = this;
      await ApiService.get("prep-app/test/" + id).then(function (response) {
        if (response.status === 200) {
          let test = response.data;
          let testCp = JSON.parse(JSON.stringify(test));

          test.skills = vm.convertDataSkill(testCp.skills);
          vm.test = test;
        }
      });
    },

  },
};
</script>

<style></style>
